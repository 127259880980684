import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Button, Link } from "@mui/material";
import rtcLogo from "./assets/rtc-logo.png";

export default function Navbar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "white", padding: '12px' }}>
        <Toolbar>
          <img src={rtcLogo} width="70px" height="70px" />
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%' }}>
            <Box sx={{ marginLeft: "12px", flexGrow: 1 }}>
              <Typography
                variant="h5"
                noWrap
                fontWeight="bold"
                lineHeight={0.7}
                sx={{ color: "#D86423" }}
              >
                AI Automation
              </Typography>
              <Typography
                variant="h6"
                noWrap
                fontStyle="italic"
                sx={{ color: "#282c34" }}
              >
                Solutions for smarter future!
              </Typography>
            </Box>
            <Box sx={{ marginTop: '3px' }}>
              {/* <Link
                href="http://localhost:7878/healenium/selectors/"
                target="_blank"
                sx={{
                  borderColor: "#D86423",
                  color: "#D86423",
                  textDecoration: 'none',
                  border: '1px solid #D86423',
                  borderRadius: '4px',
                  padding: '6px 12px',
                  alignContent: 'center'
                }}
              >
                See Helenium Report
              </Link> */}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
