import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Navbar";
import { Button, Typography } from "@mui/material";
import DisplayTestCases from "./DisplayTestCases";
import { getTestResults } from "./api";
import Swal from "sweetalert2";
import axios, { AxiosResponse } from "axios";

function App() {
  const [displayTestCases, setDisplayTestCases] = useState<boolean>(false);
  const [testData, setTestData] = useState<any[]>([]);
  // const [userIp, setUserIp] = useState<String>("");
  // const ipv4Regex =
  //   /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}$/;
  // const ipAddress = "192.168.1.1";

  // const getUserIp = async () => {
  //   if (userIp === "") {
  //     try {
  //       const resp = await axios.get("https://geolocation-db.com/json/");
  //       setUserIp(resp.data.IPv4);
  //     } catch (e) {
  //       alert(e);
  //       setTimeout(() => {
  //         if (userIp === "") {
  //           let Ip = window.prompt("Please enter your IPV4 address:");
  //           setUserIp(Ip!);
  //           if (ipv4Regex.test(Ip!)) {
  //             setUserIp(Ip!);
  //           } else {
  //             getUserIp();
  //           }
  //         }
  //       }, 1000);
  //     }
  //   }
  // };
  // console.log("Ip address", userIp);

  // useEffect(() => {
  //   console.log("Effected");
  //   getUserIp();
  // }, [userIp]);
  useEffect(() => {
    setTimeout(() => {
      const iframe = document.getElementById(
        "healeniumIframe"
      ) as HTMLIFrameElement;
      console.log(iframe.getElementsByClassName('content-wrapper-main'));
      console.log(iframe?.contentWindow?.document);
      console.log(iframe?.contentDocument?.getElementsByClassName('content-wrapper-main'));
    }, 1000)

  }, []);

  const loadData = async () => {
    const data = await getTestResults();

    if (data !== null) {
      setDisplayTestCases(true);
      setTestData(data);
    } else {
      Swal.fire({
        title: "No Data Found!",
        text: "Please make sure that you ran test cases correctly.",
        icon: "error",
        confirmButtonText: "Okie",
      });
    }
  };

  return (
    <>
      <Navbar />
      <div className="App">
        <header className="App-header">
          {/* {!displayTestCases ? (
            <div style={{ padding: '20px' }}>
              <Typography variant="h6">
                Please run your test and wait for report to generate. After
                report is
              </Typography>
              <Typography variant="h6">
                generated please click below button
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  marginTop: "20px",
                  borderColor: "#D86423",
                  color: "#D86423",
                }}
                onClick={loadData}
              >
                Load Data
              </Button> */}
          <iframe
            id="healeniumIframe"
            width="1200"
            height="650"
            src={`http://52.55.81.114:7878/healenium/selectors/`}
            title="Healenium Report"
            scrolling="no"
            style={{
              margin: "20px 0px 20px 0px",
            }}
          ></iframe>
          {/* </div> */}
        </header>
      </div>
    </>
  );
}

export default App;
